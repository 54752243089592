import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Conference from "./Conference/conference"

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Conference />} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
